import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Form1 from './Form1';
import Form2 from './Form2';
import Form3 from './Form3';
import FloorPlanForm from './FloorPlanForm';
import TowerDetailsForm from './TowerDetailsForm';
import './App.css';
import LandingPage from './components/LandingPage';

const App = () => {
  return (
    <Router>
      <div className="container">
        <Routes>
          <Route path="/form1" element={<Form1 />} />
          <Route path="/form2" element={<Form2 />} />
          <Route path="/form3" element={<Form3 />} />
          <Route path="/" element={<LandingPage/>} />
          <Route path="/floorPlanForm" element={<FloorPlanForm />} />
          <Route path="/towerDetailsForm" element={<TowerDetailsForm />} />
         
        </Routes>
      </div>
    </Router>
  );
};

export default App;



