import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TowerForm = () => {
    const [name, setName] = useState('');
    const [projectId, setProjectId] = useState('');
    const [towerId, setTowerId] = useState('');
    const [liftCount, setLiftCount] = useState('');
    const [totalUnitsInTower, setTotalUnitsInTower] = useState('');
    const [projects, setProjects] = useState([]);
    const [towers, setTowers] = useState([]);

    useEffect(() => {
        // Fetch all projects
        axios.get('https://api.gopropify.com/get/all/projects',{
            headers: {
                'x-auth-token': localStorage.getItem('AuthToken'),
            }
        })
            .then(response => setProjects(response.data))
            .then(response => console.log("Submitted data",response.data))
            .catch(error => console.error('Error fetching projects:', error));
        
        // Fetch all towers
        axios.get('https://api.gopropify.com/get/all/project/tower', {
            headers: {
                'x-auth-token': localStorage.getItem('AuthToken'),
            }
        })
            .then(response => {
                setTowers(response.data);
            })
            .catch(error => console.error('Error fetching towers:', error));
    }, []);

    useEffect(() => {
        axios.get(`https://api.gopropify.com/get/all/project/tower`, {
            headers: {
                'x-auth-token': localStorage.getItem('AuthToken'),
            },
            params: {
                projectId: projectId 
            }
        })
        .then(response => setTowers(response.data))
        .catch(error => console.error('Error fetching towers for project:', error));
    }, [projectId]);
    

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = {
            name,
            projectId,
            towerId,
            liftCount: parseInt(liftCount),
            totalUnitsInTower: parseInt(totalUnitsInTower),
        };

        axios.post('https://api.gopropify.com/save/new/project/tower', formData, {
            headers: {
                'x-auth-token': localStorage.getItem('AuthToken'),
            }
        })
            .then(response => {
                const savedId = response.data.id;
                localStorage.setItem('savedTowerId', savedId);
                console.log('Form submitted successfully:', response.data);
            })
            .catch(error => console.error('Error submitting form:', error));
    };

    return (
        <form onSubmit={handleSubmit}>
            {/* Project Dropdown */}
            <div>
                <label htmlFor="projectId">Project ID:</label>
                <select
                    id="projectId"
                    value={projectId}
                    onChange={(e) => setProjectId(e.target.value)}
                >
                    <option value="">Select Project</option>
                    {projects.map(project => (
                        <option key={project.id} value={project.id}>
                            {project.name}
                        </option>
                    ))}
                </select>
            </div>

            {/* Tower Dropdown (always visible) */}
            <div>
                <label htmlFor="towerId">Tower:</label>
                <select
                    id="towerId"
                    value={towerId}
                    onChange={(e) => setTowerId(e.target.value)}
                    required
                >
                    <option value="">Select Tower</option>
                    {towers.map(tower => (
                        <option key={tower.id} value={tower.id}>
                            {tower.name}
                        </option>
                    ))}
                </select>
            </div>

            {/* Lift Count */}
            <div>
                <label htmlFor="liftCount">Lift Count:</label>
                <input
                    type="number"
                    id="liftCount"
                    value={liftCount}
                    onChange={(e) => setLiftCount(e.target.value)}
                    required
                />
            </div>

            {/* Total Units in Tower */}
            <div>
                <label htmlFor="totalUnitsInTower">Total Units in Tower:</label>
                <input
                    type="number"
                    id="totalUnitsInTower"
                    value={totalUnitsInTower}
                    onChange={(e) => setTotalUnitsInTower(e.target.value)}
                    required
                />
            </div>

            <button type="submit">Submit</button>
        </form>
    );
};

export default TowerForm;
