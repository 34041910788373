import AWS from 'aws-sdk';
import React, { useState } from 'react';
import axios from 'axios';

const S3_BUCKET = "propify-bucket";
const REGION = "ap-south-1";
const FOLDER_NAME = "floorplan";  

AWS.config.update({
  accessKeyId: "AKIAWDYQ5B6F57VKQXUW",
  secretAccessKey: "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",
  region: REGION,
});

const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },    
});

const FloorPlanForm = () => {
    const Towerid = localStorage.getItem('savedTowerId');
    const [projectTowerId, setProjectTowerId] = useState(Towerid);
    const [number, setNumber] = useState('');
    const [floorPlanImage, setFloorPlanImage] = useState(null);  
    const [priceInSqft, setPriceInSqft] = useState('');

    const handleImageUpload = async (file) => {
        const fileName = `${Date.now()}_${file.name}`;
        const key = `${FOLDER_NAME}/${fileName}`;  

        const params = {
            Bucket: S3_BUCKET,
            Key: key,
            Body: file,
            ContentType: file.type,
            // ACL: 'public-read', 
        };

        try {
            const data = await s3.putObject(params).promise();
            const objectUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${key}`;
            console.log('File uploaded successfully at:', objectUrl);
            return objectUrl;
        } catch (error) {
            console.error('Error uploading image:', error);
            return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            projectTowerId,
            number: parseInt(number),
            floorPlanImage: '',  
            priceInSqft: parseFloat(priceInSqft),
        };

        if (floorPlanImage) {
            const imageUrl = await handleImageUpload(floorPlanImage); 
            formData.floorPlanImage = imageUrl;  
        }

        axios.post('https://api.gopropify.com/save/new/property/floor', formData, {
            headers: {
                'x-auth-token': localStorage.getItem('AuthToken'),
            }
        })
        .then(response => {
            console.log('Form submitted successfully:', response.data);
        })
        .catch(error => console.error('Error submitting form:', error));
    };
    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label htmlFor="projectTowerId">Project Tower ID:</label>
                <input
                    type="text"
                    id="projectTowerId"
                    value={projectTowerId}
                    onChange={(e) => setProjectTowerId(e.target.value)}
                    required
                />
            </div>
            <div>
                <label htmlFor="number">Floor Number:</label>
                <input
                    type="text"
                    id="number"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    required
                />
            </div>
            <div>
                <label htmlFor="floorPlanImage">Floor Plan Image:</label>
                <input
                    type="file"
                    id="floorPlanImage"
                    onChange={(e) => setFloorPlanImage(e.target.files[0])} 
                    required
                />
            </div>
            <div>
                <label htmlFor="priceInSqft">Price per Sqft:</label>
                <input
                    type="text"
                    id="priceInSqft"
                    value={priceInSqft}
                    onChange={(e) => setPriceInSqft(e.target.value)}
                    required
                />
            </div>
            <button type="submit">Submit</button>
        </form>
    );
};

export default FloorPlanForm;
