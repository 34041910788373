import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import AWS from 'aws-sdk';

const S3_BUCKET = "propify-bucket";
const REGION = "ap-south-1";
const FOLDER_NAME = "projectconfiguration";

AWS.config.update({
  accessKeyId: "AKIAWDYQ5B6F57VKQXUW",
  secretAccessKey: "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",
  region: REGION,
});

const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
});

const Form2 = () => {
  const location = useLocation();
  const { propertyConfigTypeId } = location.state || {};
  const [name, setName] = useState('');
  const [sizeInSqft, setSizeInSqft] = useState('');
  const [floorPlanImage, setFloorPlanImage] = useState(null);
  const [specifications, setSpecifications] = useState([{ specification: '', count: '' }]);
  const [specOptions, setSpecOptions] = useState([]);
  const [propertyConfigTypes, setPropertyConfigTypes] = useState([]);
  const [selectedPropertyConfigType, setSelectedPropertyConfigType] = useState('');
  const [selectedPropertyConfigTypeName, setSelectedPropertyConfigTypeName] = useState('');
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  const handleImageUpload = async (file) => {
    const fileName = `${Date.now()}_${file.name}`;
    const key = `${FOLDER_NAME}/${fileName}`;

    const params = {
      Bucket: S3_BUCKET,
      Key: key,
      Body: file,
      ContentType: file.type,
    };

    try {
      const data = await s3.putObject(params).promise();
      const objectUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${key}`;
      console.log('File uploaded successfully at:', objectUrl);
      return objectUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchSpecOptions = async () => {
      try {
        const response = await axios.get('https://api.gopropify.com/get/all/specifications');
        setSpecOptions(response.data);
      } catch (error) {
        console.error('Error fetching specifications:', error);
      }
    };
    fetchSpecOptions();
  }, []);

  useEffect(() => {
    const fetchPropertyConfigTypes = async () => {
      try {
        const response = await axios.get('https://api.gopropify.com/get/all/property/configuration/type');
        setPropertyConfigTypes(response.data);
      } catch (error) {
        console.error('Error fetching property configuration types:', error);
      }
    };
    fetchPropertyConfigTypes();
  }, []);

  const handleSpecificationChange = (index, field, value) => {
    const newSpecifications = [...specifications];
    newSpecifications[index][field] = value;
    setSpecifications(newSpecifications);
  };

  const addSpecification = () => {
    setSpecifications([...specifications, { specification: '', count: '' }]);
  };

  const handlePropertyConfigTypeChange = (e) => {
    const selectedId = e.target.value;
    setSelectedPropertyConfigType(selectedId);

    const selectedType = propertyConfigTypes.find((type) => type.id === selectedId);
    setSelectedPropertyConfigTypeName(selectedType ? selectedType.name : '');
  };

  const handleFileChange = (e) => {
    setFloorPlanImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedPropertyConfigType) {
      alert('Please select a Property Configuration Type.');
      return;
    }

    const validSpecifications = specifications.filter(
      (spec) => spec.specification && spec.count
    );

    if (validSpecifications.length === 0) {
      alert('Please add valid specifications.');
      return;
    }

    let imageUrl = '';
    if (floorPlanImage) {
      setUploading(true);
      imageUrl = await handleImageUpload(floorPlanImage);
      setUploading(false);

      if (!imageUrl) {
        alert('Image upload failed.');
        return;
      }
    }

    const data = {
      name,
      sizeInSqft: Number(sizeInSqft),
      propertyConfigType: selectedPropertyConfigType,
      floorPlanImage: imageUrl,
      specifications: validSpecifications,
    };

    try {
      const response = await axios.post('https://api.gopropify.com/save/new/property/configuration', data, {
        headers: {
          'x-auth-token': localStorage.getItem('AuthToken'),
        },
      });

      console.log('Property configuration saved successfully:', response.data);
      const propertyConfigId = response.data.id;

      navigate('/form3', { state: { propertyConfigId, selectedPropertyConfigTypeName } });
    } catch (error) {
      console.error('Error saving property configuration:', error.response?.data || error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Size (in Sqft):</label>
        <input
          type="number"
          value={sizeInSqft}
          onChange={(e) => setSizeInSqft(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Floor Plan Image:</label>
        <input type="file" onChange={handleFileChange} accept="image/*" />
      </div>
      <div>
        <label>Property Configuration Type:</label>
        <select
          value={selectedPropertyConfigType}
          onChange={handlePropertyConfigTypeChange}
          required
        >
          <option value="">Select Configuration Type</option>
          {propertyConfigTypes.map((type) => (
            <option key={type.id} value={type.id}>
              {type.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>Specifications:</label>
        {specifications.map((spec, index) => (
          <div key={index} className="specification-row">
            <select
              value={spec.specification}
              onChange={(e) => handleSpecificationChange(index, 'specification', e.target.value)}
              required
            >
              <option value="">Select Specification</option>
              {specOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            <input
              type="number"
              value={spec.count}
              onChange={(e) => handleSpecificationChange(index, 'count', e.target.value)}
              placeholder="Count"
              required
            />
          </div>
        ))}
        {/* <button
          type="button"
          onClick={addSpecification}
          className="add-spec-btn"
        >
          +
        </button> */}
        <button
          type="button"
          onClick={addSpecification}
          style={{
            backgroundColor: '#007bff',
            color: 'white',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            border: 'none',
            fontSize: '20px',
            lineHeight: '30px',
            textAlign: 'center',
            padding: '0px',
          }}
        >
          +
        </button>
      </div>
      <button type="submit" disabled={uploading}>
        {uploading ? 'Uploading...' : 'Next'}
      </button>
    </form>
  );
};

export default Form2;
