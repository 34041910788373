// import React, { useEffect, useState } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import axios from 'axios';
// // import {data} from './Form1'

// const Form2 = () => {
//   const location = useLocation();
//   const params = new URLSearchParams(location.search);
//   const propertyTypeId = params.get('propertyTypeId');
//   const [name, setName] = useState('');
//   const [sizeInSqft, setSizeInSqft] = useState('');
//   const [propertyConfigType, setPropertyConfigType] = useState('');
//   const [floorPlanImage, setFloorPlanImage] = useState('');
//   const [specifications, setSpecifications] = useState([{ specification: '', count: '' }]);
//   const [configTypes, setConfigTypes] = useState([]);
//   const [specOptions, setSpecOptions] = useState([]);
//   const navigate = useNavigate();

//   // Fetch configuration types and specifications from the API
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const [configTypesResponse, specOptionsResponse] = await Promise.all([
//           axios.get('https://api.gopropify.com/get/all/property/configuration/type'),
//           axios.get('https://api.gopropify.com/get/all/specifications')
//         ]);
//         setConfigTypes(configTypesResponse.data);
//         setSpecOptions(specOptionsResponse.data);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };
//     fetchData();
//   }, []);

 
//   const handleSpecificationChange = (index, field, value) => {
//     const newSpecifications = [...specifications];
//     newSpecifications[index][field] = value;
//     setSpecifications(newSpecifications);
//   };


//   const addSpecification = () => {
//     setSpecifications([...specifications, { specification: '', count: '' }]);
//   };

 
//   const handleSubmit = (e) => {
//     e.preventDefault();
    
//     const selectedConfigType = configTypes.find(config => config.id === propertyConfigType);
//     const formData = {
//       name,
//       sizeInSqft,
//       propertyConfigTypeId: propertyConfigType,  
//       propertyConfigTypeName: selectedConfigType ? selectedConfigType.name : '',  // Pass the name
//       floorPlanImage,
//       specifications
//     };
    
   
//     navigate(`/form3?propertyConfigTypeId=${propertyConfigType}&propertyConfigTypeName=${selectedConfigType.name}&data=${encodeURIComponent(JSON.stringify(formData))}`);
//   };
  
//   return (
//     <form onSubmit={handleSubmit}>
//       <div>
//         <label>Name:</label>
//         <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
//       </div>
//       <div>
//         <label>Size (in Sqft):</label>
//         <input type="text" value={sizeInSqft} onChange={(e) => setSizeInSqft(e.target.value)} required />
//       </div>
//       <div>
//         <label>Floor Plan Image URL:</label>
//         <input type="text" value={floorPlanImage} onChange={(e) => setFloorPlanImage(e.target.value)} required />
//       </div>
//       <div>
//         <label>Property Configuration Type:</label>
//         <select value={propertyConfigType} onChange={(e) => setPropertyConfigType(e.target.value)} required>
//           <option value="">Select Configuration Type</option>
//           {configTypes.map((config) => (
//             <option key={config.id} value={config.id}>
//               {config.name}
//             </option>
//           ))}
//         </select>
//       </div>
//       <div>
//         <label>Specifications:</label>
//         {specifications.map((spec, index) => (
//           <div key={index} className="specification-row">
//             <select
//               value={spec.specification}
//               onChange={(e) => handleSpecificationChange(index, 'specification', e.target.value)}
//               required
//             >
//               <option value="">Select Specification</option>
//               {specOptions.map((option) => (
//                 <option key={option.id} value={option.id}>
//                   {option.name}
//                 </option>
//               ))}
//             </select>
//             <div>
//               <label>Count:</label>
//               <input
//                 type="text"
//                 value={spec.count}
//                 onChange={(e) => handleSpecificationChange(index, 'count', e.target.value)}
//                 required
//               />
//             </div>
//           </div>
//         ))}
//       </div>
//       <button type="submit">Next</button>
//     </form>
//   );
// };

// export default Form2;
import React from 'react'
import Form1 from '../Form1'

const LandingPage = () => {
  return (
    <div>
      <Form1/>
    </div>
  )
}

export default LandingPage
