import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Form3 = () => {
  const location = useLocation();
  const { propertyConfigId ,selectedPropertyConfigTypeName} = location.state || {};  
  const [totalUnits, setTotalUnits] = useState('');
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch('https://api.gopropify.com/get/all/projects');
        const data = await response.json();
        setProjects(data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };
    fetchProjects();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const finalFormData = {
      'projectId': selectedProjectId, 
      "propertyConfigurationId": propertyConfigId,  
      "totalUnits": totalUnits,
    };
  
    try {
      const storedToken = localStorage.getItem('AuthToken');
      if (!storedToken) {
        console.error('No AuthToken found');
        return;
      }
  
      const response = await fetch('https://api.gopropify.com/save/new/project/configuration', {
        method: 'POST',
        headers: {
          'x-auth-token': storedToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(finalFormData),  
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log('Form submitted successfully:', result);
      } else {
        const errorDetails = await response.json();
        console.error('Failed to submit the form:', errorDetails);
      }
    } catch (error) {
      console.error('Error submitting the form:', error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Project:</label>
        <select
          value={selectedProjectId}
          onChange={(e) => setSelectedProjectId(e.target.value)}
          required
        >
          <option value="" disabled>Select a project</option>
          {projects.map((project) => (
            <option key={project.id} value={project.id}>
              {project.name}
            </option>
          ))}
        </select>
      </div>
      
      
      <div>
        <label>Property Configuration ID:</label>
        <input 
          type="text" 
          value={selectedPropertyConfigTypeName || ''} 
          readOnly 
        />
      </div>
      
      <div>
        <label>Total Units:</label>
        <input 
          type="text" 
          value={totalUnits} 
          onChange={(e) => setTotalUnits(e.target.value)} 
          required 
        />
      </div>
      
      <button type="submit">Submit</button>
    </form>
  );
};

export default Form3;
