import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './App.css'; 

const Form1 = () => {
  const [name, setName] = useState('');
  const [propertyTypeId, setPropertyTypeId] = useState('');
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [nameError, setNameError] = useState('');
  const [propertyTypeError, setPropertyTypeError] = useState('');
  const [token, setToken] = useState('');
  const navigate = useNavigate();

 
  useEffect(() => {
    const fetchTokenAndPropertyTypes = async () => {
      try {
        const credential = { username: '9161622129', otp: '123456' };
        const tokenResponse = await axios.post('https://api.gopropify.com/user/generate/login/token', credential);
        const fetchedToken = tokenResponse.data.token;
        localStorage.setItem('AuthToken',fetchedToken);
        console.log(fetchedToken);
        setToken(fetchedToken);

        const propertyTypesResponse = await axios.get('https://api.gopropify.com/get/all/property/type', {
          headers: { 'x-auth-token': fetchedToken }

        });
        console.log(propertyTypesResponse);
        console.log('getAllPropertyType');
        const propertyTypes = propertyTypesResponse.data.map(type => ({
          id: type.id,
          name: type.name,
        }));
        setPropertyTypes(propertyTypes);
      } catch (error) {
        console.error('Error fetching token or property types:', error);
      }
    };

    fetchTokenAndPropertyTypes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;
  
    if (!name) {
      setNameError('Name is required');
      valid = false;
    } else {
      setNameError('');
    }
  
    if (!propertyTypeId) {
      setPropertyTypeError('Property Type is required');
      valid = false;
    } else {
      setPropertyTypeError('');
    }
  
    if (valid) {
      try {
        const data = { 'propertyTypeId': propertyTypeId, 'name': name };
        const response = await axios.post('https://api.gopropify.com/save/new/property/configuration/type', data, {
          headers: { 'x-auth-token': token },
        });
  
        const { id: propertyConfigTypeId } = response.data; 
        console.log(response.data);
        localStorage.setItem('PropertyConfigId', propertyConfigTypeId); 
       
        
        navigate('/form2', { state: { name, propertyTypeId, propertyConfigTypeId } });
      } catch (error) {
        console.error('Error saving form data:', error);
      }
    }
  };
  

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Property Configuration Type:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        {nameError && <span className="error">{nameError}</span>}
      </div>
      <div>
        <label>Property Type:</label>
        <select
          value={propertyTypeId}
          onChange={(e) => setPropertyTypeId(e.target.value)}
          required
        >
          <option value="">Select Property Type</option>
          {propertyTypes.map((type) => (
            <option key={type.id} value={type.id}>
              {type.name}
            </option>
          ))}
        </select>
        {propertyTypeError && <span className="error">{propertyTypeError}</span>}
      </div>
      <button type="submit">Next</button>
    </form>
  );
};

export default Form1;

